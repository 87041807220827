.button {
  background: rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  text-decoration: none;
  color: orange;
  border: 2px solid #1c1c1c;
  border-radius: 10px;
  padding: 7px 20px;
  margin: 0 10px;
}

.button img {
  width: 42px;
  margin-right: 16px;
}

.button span {
  font-size: 38px;
  margin-bottom: 10px;
}

@media screen and (orientation: portrait) {
  .button {
    padding: 8px 15px 12px;
    margin: 0 7px;
  }
  .button img {
    width: 32px;
    margin-right: 12px;
  }
  .button span {
    font-size: 1.5em;
    margin-bottom: 0;
  }
}