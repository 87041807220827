.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f16f17;
}

.for_sale_container {
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 12px 62px 40px;
  border-radius: 20px;
}

.for_sale {
  font-size: 70px;
}

.text_contact {
  font-size: 30px;
  padding: 20px 0;
}

.buttons {
  display: flex;
}

.hosting {
  position: absolute;
  bottom: 10px;
  border-radius: 15px;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(10px);
}

.hosting a {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  text-decoration: none;
  color: #3393ff;
  font-size: 18px;
}

.hosting img {
  height: 28px;
  margin-bottom: 3px;
}

@media screen and (orientation:portrait) {
  .main {
    margin: 40% 2px;
  }
  .for_sale_container {
    margin: 0;
    padding: 20px 0;
    width: 100%;

    border-radius: 5px;
  }

  .for_sale {
    font-size: 2.5em;
  }


}